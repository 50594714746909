import React, { Component } from 'react';

class Logo extends Component {
    constructor(props) {
        super(props);
        this.state = { menuMobile: false, loc: this.props.loc }
        this.fadeMenuOut = this.fadeMenuOut.bind(this) 
    }

    fadeMenuOut() {
        if (window.innerWidth <= 700 && this.props.loc === "home") {
            document.getElementById('menuList').style.setProperty("opacity", "0");
            document.getElementById('nav').style.setProperty("top", "-2000px");
            document.getElementById('btnHamburger').innerText = "☰";
            document.getElementById('btnHamburger').style.setProperty("color", "#fff");
            document.getElementById('logo').classList.remove("dark");
        }
    }

    render() {
        return (  
            <div className="logo" id="logo"><a href="../">Guillaume<br />Simoneau</a></div>
        );
    }
}

export default Logo;