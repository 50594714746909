import React, { Component } from 'react';
import Img from './Img';

class MyImage extends Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: false }
    }
    
    componentDidMount() {
    }

    loaded(index) {
        //console.log("loaded:"+index);
        document.getElementById("myImage").classList.remove("load");
        document.getElementById("myDetails").classList.remove("load");
    }

    render() {
        if (this.props.isLoading) {
            return "...";
        }
        let imageSrc = this.props.imageSrc;
        let imageTitle = this.props.imageTitle;
        let imageCaption = this.props.imageCaption;
        let imageIndex = this.props.imageIndex;
        return (
            <div className="">
                    <div>
                        <Img src={imageSrc} id="myImage" className="load" alt={imageTitle} onLoad={()=> this.loaded(imageIndex)} />
                    </div>
                    <div className="details load" id="myDetails">
                        <div className="imageTitle" dangerouslySetInnerHTML={{__html: imageTitle}}></div>
                        <div className="imageCaption" dangerouslySetInnerHTML={{__html: imageCaption}}></div>
                    </div>
            </div>
        );
    }
}

export default MyImage;