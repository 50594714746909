import React, { Component } from 'react';
import { HashRouter, Routes, Route, Link } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";
import SetPage from '../pages/SetPage';
import PrivacyPage from '../pages/PrivacyPage';
import ImprintPage from '../pages/ImprintPage';
import i18n from '../i18n';
import { HelmetProvider } from 'react-helmet-async';

class Router extends Component {

    render() {
        return (
            <HashRouter>
                <HelmetProvider>
                    <CookieConsent
                        location="bottom"
                        buttonText={i18n.t('thank you I agree')}
                        cookieName="GS GDPR 1"
                        expires={150}>
                            {i18n.t('we use cookies')} <Link to='/privacy'>{i18n.t('we value your privacy')}</Link>
                    </CookieConsent>
                    <Routes>
                        <Route path="/" element={<SetPage /> }/>
                        <Route path="/set" element={<SetPage /> }/>
                        <Route path="/privacy" element={<PrivacyPage /> }/>
                        <Route path="/imprint" element={<ImprintPage /> }/>
                    </Routes>
                </HelmetProvider>
            </HashRouter>
        );
    }
}

export default Router;