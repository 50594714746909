import React, { Component } from 'react';
import Img from './Img';

class MyImageThumb extends Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: false }
    }
    
    componentDidMount() {
    }

    loaded() {
        //document.getElementById("myImage").classList.remove("load");
    }

    render() {
        if (this.props.isLoading) {
            return "...";
        }
        let imageSrc = this.props.imageSrc;
        let imageTitle = this.props.imageTitle;
        return (
            <div className="">
                    <div>
                        <Img src={imageSrc} title={imageTitle} alt={imageTitle} id="myImage" onLoad={()=> this.loaded()} />
                    </div>
            </div>
        );
    }
}

export default MyImageThumb;