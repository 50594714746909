import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import i18n from '../i18n';

class Footer extends Component {
    render() {
        return (  
            <div className="footer">
                <div className="links">
                    <NavLink to='/privacy'>{i18n.t('privacy')}</NavLink> &nbsp; / &nbsp;
                    <NavLink to='/imprint'>{i18n.t('imprint')}</NavLink>
                </div>
                <div className="credits">2020 © Guillaume Simoneau</div>
            </div>
        );
    }
}

export default Footer;