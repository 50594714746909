import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const API = 'https://www.admin.simoneauguillaume.com/wp-json/wp/v2/';
//const API = 'http://localhost:8888/admin-gs/wp-json/wp/v2/';
const QUERY = 'photo_set?_embed&filter[orderby]=menu_order&order=asc&per_page=100';

class MenuSets extends Component {
    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        //let addLang = i18n.language.substr(0,2); if (addLang === i18n.options.fallbackLng[0]) {addLang = '';} else {addLang = '-'+addLang;}
        fetch(API+QUERY)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        if (this.state.isLoading) {
            //return <Loading />;
        }

        let pathArray = window.location.pathname.split('/');
        let pathSlug = pathArray[1];
        let mainUrl = "set/";
        if (pathSlug === "set" && pathArray[3] !== undefined) {
             mainUrl = "../";
        }
        else if (pathSlug === "set") {
            mainUrl = "";
        }

        let page = this.state.dataSet.map((data, index) => {
            let slug = data.slug;
            let title = data.title.rendered;
            return (
                <li key={index}>
                    <a href={mainUrl+slug}  dangerouslySetInnerHTML={{__html: title}}></a>
                </li>
            );
        });
        return (
            <div>
                 <li className="logoTop">
                     <a href="../" >Guillaume<br />Simoneau</a>
                </li>
                { page }
                {/*<li onClick={() => this.fadeMenuOut()}>
                    <NavLink to='/motion'>Motion</NavLink>
                </li>
                <li onClick={() => this.fadeMenuOut()}>
                    <NavLink to='/contact'>Contact</NavLink>
                 </li>*/}
            </div>
        );
    }
}

export default MenuSets;