import React, { Component } from 'react';
import MyImageThumb from './MyImageThumb';

class AttachedImages extends Component {
    constructor(props) {
        super(props);
        this.state = {dataSet: this.props.data, setSlug: this.props.setSlug, isLoading: true }
    }
    
    componentDidMount() {
        
    }

    render() {
        const { dataSet } = this.state;
        // MOBILE DETECT
        let mobile = false; 
        if (window.innerWidth <= 767)
            mobile = true;
        let images = dataSet.map((image, index) => {
            let imageSet = "";
            let imageUrl = "";
            if (mobile) {
                imageSet = image.medium_srcset.split(" ");
                imageUrl = imageSet[2];
            } else {
                imageSet = image.medium_srcset.split(" ");
                imageUrl = imageSet[0];
            }
            let imageTitle = image.title;
            let imageLink = image.url;
            let hasLink = false;
            if (imageLink !== "")
                hasLink = true;
            
            // ANCHOR
            let pathArray = window.location.pathname.split('/');
            let slug = pathArray[1];
            let url = "";
            if (slug === "set" && pathArray[3] === undefined)
                url = this.state.setSlug+"/"+(index+1);
            else if (pathArray[3] !== undefined)
                url = ""+(index+1);
            else
                url = "set/"+this.state.setSlug+"/"+(index+1);

            return (
                <div className="attachedImage" key={index}>
                    {mobile ? (
                            hasLink ? (
                                <a href={imageLink} target="_blank" rel="noopener noreferrer">
                                    <div><MyImageThumb imageSrc={imageUrl} imageTitle={imageTitle} /></div>
                                </a>
                            )
                            : (
                                <div><MyImageThumb imageSrc={imageUrl} imageTitle={imageTitle} /></div>
                            )
                        )
                        : (
                            hasLink ? (
                                <a href={imageLink} target="_blank" rel="noopener noreferrer">
                                    <div><MyImageThumb imageSrc={imageUrl} imageTitle={imageTitle} /></div>
                                </a>
                            )
                            : (
                                <a href={url}>
                                <div><MyImageThumb imageSrc={imageUrl} imageTitle={imageTitle} /></div>
                                </a>
                            )
                        )
                    }
                </div>
            )
        });
        return (
            <div className="attachedImages">
                { images }
            </div>
        );
    }
}

export default AttachedImages;