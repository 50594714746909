import React, { Component } from 'react';
import Loading from './Loading';
import MyImage from './MyImage';

class ImageGallery extends Component {
    constructor(props) {
        super(props);
        this.state = {dataSet: this.props.data, setSlug: this.props.setSlug, currentId: 0, nextId: 0, prevId: 0, isLoading: true }
    }
    
    componentDidMount() {
        let pathArray = window.location.pathname.split('/');
        // INITIALIZE NAVIGATION
        let current = parseInt(pathArray[3]);
        let previous = current - 1; 
        let next = current + 1;
        if (previous === 0)
            previous = 1;
        if (next > this.state.dataSet.length)
            next = this.state.dataSet.length;
        this.setState({dataSet: this.state.dataSet, currentId: current, prevId: previous, nextId: next, isLoading: false });
        window.addEventListener("keydown", this.navFromKey);
    }

    componentWillUnmount(){
        window.removeEventListener("keydown", this.navFromKey);
    }

    setValuesNavigation(myCurrent) {
        let update = true;
        let current = myCurrent;
        let previous = current - 1; 
        let next = current + 1;
        if (previous === 0) {
            previous = 1;
            update = false;
        }
        if (next > this.state.dataSet.length) {
            next = this.state.dataSet.length;
            update = false;
        }   
        if (update) {
            //document.getElementById("myImage").src = "";
            this.state.isLoading = true;
            document.getElementById("myDetails").classList.add("load");
            document.getElementById("myImage").classList.add("load");
        }
        this.setState({dataSet: this.state.dataSet, currentId: current, prevId: previous, nextId: next, isLoading: false });
        //this.setState({dataSet: this.state.dataSet, currentId: current, prevId: previous, nextId: next, isLoading: false },() => { console.log('new state', this.state); });
    }
      
    moveBack() {
        this.setValuesNavigation(this.state.prevId);

    }

    moveForward() {
        this.setValuesNavigation(this.state.nextId);
    }

    navFromKey = (event) => {
        if(event.keyCode === 37) {
            this.moveBack();
        }
        if(event.keyCode === 39) {
            this.moveForward();
        }
    }

    render() {
        if (this.state.isLoading) {
            return "...";
        }
        let dataSet = this.state.dataSet;
        let hiddenTitle = this.props.hiddenTitle;
        let addStyle = ""
        let myTotalImages = dataSet.length;
        let currentPosSelected = this.state.currentId;
        window.history.pushState("", "", ""+this.state.currentId );
        let selImageSet = dataSet[this.state.currentId -1].medium_srcset.split(" ");
        let selImageUrl = selImageSet[8]; //image resolution picked
        if (selImageUrl === undefined) {
            selImageUrl = selImageSet[6]; //image resolution picked
            if (selImageUrl === undefined) {
                selImageUrl = selImageSet[4]; //image resolution picked
                if (selImageUrl === undefined)
                    selImageUrl = selImageSet[2]; //image resolution picked
            }
        }
        let selImageTitle = dataSet[this.state.currentId -1].title;
        let selImageCaption = dataSet[this.state.currentId -1].caption;

        if (hiddenTitle)
            addStyle = "hiddenTitle";
        return (
            <div className="attachedImages">
                    <div className="navigation">
                        <div className="arrow left" onClick={()=> this.moveBack()}>←</div> &nbsp; 
                        <div className="arrow right" onClick={()=> this.moveForward()}>→</div> &nbsp;
                        {currentPosSelected} / {myTotalImages} &nbsp;&nbsp; 
                        <a className="text" href={"../"+this.state.setSlug}>Back to Thumbnails</a>
                    </div>
                    <Loading />
                    <div className={"attachedImage show "+ addStyle} id="myViewer">
                        <MyImage imageSrc={selImageUrl} imageTitle={selImageTitle} imageCaption={selImageCaption} imageIndex={this.state.currentId -1} />
                    </div>
                        {/*<PreloadImages dataSet={this.state.dataSet} />*/}
            </div>
        );
    }
}

export default ImageGallery;