import React, { Component } from 'react';
import Footer from '../components/Footer';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

const API = 'https://www.admin.simoneauguillaume.com/';
const QUERY = 'pages/?slug=imprint';

class PrivacyPage extends Component {

    constructor(props) {
        super(props);
        this.state = { dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        let addLang = i18n.language.substr(0,2); if (addLang === i18n.options.fallbackLng[0]) {addLang = '';} else {addLang = '-'+addLang;}
        fetch(API+QUERY+addLang)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        let title = this.state.dataSet.map((data) => { return ( data.title.rendered )});
        let content = this.state.dataSet.map((data) => { return ( data.content.rendered )});
        return (
            <div>
                <Helmet> 
                        <meta lang={i18n.language.substr(0,2)} /> 
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title imprint')}</title>
                </Helmet>
                <div className="wrapperSqueeze">
                    <div className="privacy">
                        <div className="sectionTitle">
                            <div className="anchor">{title}</div>
                        </div>
                        <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>
                    </div>
                </div>
                < Footer />
            </div>
        );
    }
}

export default PrivacyPage;