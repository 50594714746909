import React, { Component } from 'react';
import AttachedImages from './AttachedImages';
import ImageGallery from './ImageGallery';

const API = 'https://www.admin.simoneauguillaume.com/wp-json/wp/v2/';
const QUERY = 'photo_set?_embed&orderby=title&per_page=1';

class PhotoSet extends Component {
    constructor(props) {
        super(props);
        this.state = { slug: this.props.slug, dataSet: [], isLoading: true }
    }
    
    componentDidMount() {
        let addQuery = "";
        if (this.state.slug !== "")
            addQuery = "&slug="+this.state.slug;
        fetch(API+QUERY+addQuery)
            .then(response => {
                if  (response.ok) {
                    return response.json();
                } else {
                    throw new Error('Data unavailbale.');
                }
            })
        .then(response => { this.setState({ dataSet: response, isLoading: false }) })
        .catch(error => this.setState({ error, isLoading: false }));
    }

    render() {
        let page = this.state.dataSet.map((data, index) => {
            let title = data.title.rendered;
            let content = data.content.rendered;
            let attachedImages = data.acf_photo_gallery;
            let dislayTitle = data.acf.title_display;
            let hideTitle = false;
            if (dislayTitle !== null) {        
                if (dislayTitle[0] === "Hide the section title")
                    hideTitle = true;
            }

            //let imageUrl = fPostHasImage(data, "sourceUrl");
            let pathArray = window.location.pathname.split('/');
            if (pathArray[3] === undefined) {
                return (
                    <div className="photoSet" key={index}>
                        { hideTitle ? (
                                <span></span>
                            ) : 
                            (
                                <div className="title" dangerouslySetInnerHTML={{__html: title}}></div>
                            )
                        }
                        { content ? (
                                <div className="content" dangerouslySetInnerHTML={{__html: content}}></div>
                            ) : 
                            (
                                <div className="thinSpacer"></div>
                            )
                        }
                        <AttachedImages data={attachedImages} setSlug={this.state.slug} />
                    </div>
                );
            }
            else {
                return (
                    <div className="photoGallery" key={index}>
                        { hideTitle ? (
                                <div className="thinSpacer"></div>
                            ) : 
                            (
                                <a href={"../"+this.state.slug} ><div className="title" dangerouslySetInnerHTML={{__html: title}}></div></a>
                            )
                        }
                        <ImageGallery data={attachedImages} hiddenTitle={hideTitle} setSlug={this.state.slug} />
                    </div>
                );
            }
        });
        return (
            <div>
                { page }
            </div>
        );
    }
}

export default PhotoSet;