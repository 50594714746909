import React, { Component } from 'react';
import i18n from '../i18n';
import MenuSets from './MenuSets.js';

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = { menuMobile: false, mode: this.props.mode };
        this.fadeMenuIn = this.fadeMenuIn.bind(this);
    }

    fadeMenuIn() {
        if (window.innerWidth <= 1024) {
            this.setState({ menuMobile: !this.state.menuMobile})
            if(this.state.menuMobile) {
                this.fadeMenuOut()
                document.getElementById('btnHamburger').style.setProperty("font-size", "32px");
                document.getElementById('btnHamburger').innerText = "☰";
                //document.getElementById('nav').style.setProperty("top", "-2000px");
                document.getElementById('nav').style.setProperty("z-index", "-20000");
                document.getElementById('logo').style.setProperty("display", "block");
                document.getElementById('menuList').style.setProperty("height", "0px");
            }
            else {
                document.getElementById('menuList').style.setProperty("opacity", "1");
                //document.getElementById('nav').style.setProperty("top", "0px");
                document.getElementById('nav').style.setProperty("z-index", "20000");
                document.getElementById('logo').style.setProperty("display", "none");
                document.getElementById('menuList').style.setProperty("height", "100vh");
                document.getElementById('btnHamburger').style.setProperty("font-size", "24px");
                document.getElementById('btnHamburger').innerText = "✕";
                document.getElementById('btnHamburger').style.setProperty("color", "#333");
            }
        }
    }

    fadeMenuOut() {
        if (window.innerWidth <= 1024) {
            document.getElementById('menuList').style.setProperty("opacity", "0");
            //document.getElementById('nav').style.setProperty("top", "-2000px");
            document.getElementById('nav').style.setProperty("z-index", "-20000");
            document.getElementById('menuList').style.setProperty("height", "0px");
            document.getElementById('logo').style.setProperty("display", "block");
            document.getElementById('btnHamburger').style.setProperty("font-size", "32px");
            document.getElementById('btnHamburger').innerText = "☰";
        }
    }

    changeLang(lang) {
        document.getElementById('App').style.setProperty("opacity", "0");
        window.location.reload(false);
        i18n.changeLanguage(lang);
    }
    
    render() {
        return (
                <div>
                    <div className="btnHamburger" id="btnHamburger" onClick={() => this.fadeMenuIn()} >☰</div>
                    <nav className="menuTop" id="nav">
                        <ul className="menuList" id="menuList">
                           <MenuSets />
                            {/*}
                           <li onClick={() => this.fadeMenuOut()}>
                                <div href="#" className="langSel" onClick={() => this.changeLang(i18n.t('other language code'))}>{i18n.t('other language')}</div>
                            </li>*/}
                        </ul>
                    </nav>
                </div>
        );
    }
}

export default Navigation;