import React, { Component } from 'react';
import Navigation from '../components/Navigation';
import Logo from '../components/Logo';
import PhotoSet from '../components/PhotoSet';
import i18n from '../i18n';
import { Helmet } from 'react-helmet-async';

class SetPage extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    render() {
        let pathArray = window.location.pathname.split('/');
        let slug = pathArray[2];
        if (slug === undefined || slug === "") {
            return (
                <div className="set">
                    <Helmet> 
                        <meta lang={i18n.language.substr(0,2)} /> 
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title homepage')}</title>
                    </Helmet>
                    <Navigation mode="" />
                    <Logo loc="" />
                    <div className="wrapper">
                        <PhotoSet slug="overview" />
                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="set">
                    <Helmet> 
                        <meta lang={i18n.language.substr(0,2)} /> 
                        <title>{i18n.t('meta site title')} {i18n.t('meta site title homepage')}</title>
                    </Helmet>
                    <Navigation mode="" />
                    <Logo loc="" />
                    <div className="wrapper">
                        <PhotoSet slug={slug} />
                    </div>
                </div>
            );
        }
    }
}

export default SetPage;